@font-face {
  font-family: 'Proxima Nova';
  font-display: optional;
  src: url('public/fonts/proximanova-regular-webfont.woff2') format('woff2'),
    url('public/fonts/proximanova-regular-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Proxima Nova';
  font-display: optional;
  src: url('public/fonts/proximanova-bold-webfont.woff2') format('woff2'),
    url('public/fonts/proximanova-bold-webfont.woff') format('woff');
  font-weight: 700;
}
